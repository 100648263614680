export const Endpoints = {
 login: 'auth',
 register: 'register',
 logOut: 'auth/logout',
 subscriber: 'subscriber',
 subscriberExpiry: 'subscriber/subscriber-expiry',
 updateDocTitle: 'subscriber/document-title',
 editDocTitle: 'subscriber/edit-title',
 changePassword: 'auth/reset-password',
 user: 'user',
 resetPassword: 'user/change-password',
 playerId: 'user/playerId',
 getAllUsers: 'user/users',
 getAllUsersWithReciept: 'user/users-with-reciept',
 invitation: 'invitation/send',
 matter: 'matter',
 getMatterList: 'matter/matters',
 getMatter: 'matter/details',
 myMatter: 'matter/my-matters',
 matterTask: 'matter/task',
 getTasks: 'matter/tasks',
 getAgenda: 'matter/agenda',
 getOverdueTasks: 'matter/overdue-tasks',
 searchTasks: 'matter/search-tasks',
 deleteTask: 'matter/remove-task',
 matterClaimStep: 'matter/claim-step',
 claimStep: 'claim-step',
 members: 'matter/members',
 getLawyer: 'user/lawyers',
 replaceClaimSteps: 'matter/replace-claim-steps',
 file: 'file',
 updateDocumentName: 'file/update-document-name',
 addNotes: 'file/file-notes',
 setFileList: 'file/file-list',
 getEmail: 'user/search',
 getClerks: 'user/clerks',
 downloadFile: 'file/download',
 addDate: 'matter/add-claim-date',
 getTaskByDate: 'calendar',
 addAnswer: 'matter/add-answer',
 getAnswer: 'matter/get-answer',
 getSavedForms: 'matter/get-saved-form',
 deleteFormInstance: 'matter/delete-form-instance',
 saveForm: 'matter/save-form',
 addForm: 'form',
 matterStatus: 'matter/matter-status',
 disableUser: 'user/disable',
 updateUser: 'user/update',
 precedent: 'precedent',
 updatePrecedent: 'precedent/update-precedent',
 deletePrecedent: 'precedent/remove-precedent',
 precedentCat: 'precedent-category',
 sendMail: 'sendMail',
 sendReferenceMail: 'sendMail/send-refernece-mail',
 deleteAnswer: 'matter/remove-answer',
 showSharedFiles: 'file/shared-files',
 getCaseTask: 'matter/case-task',
 jurisdictionAdd: 'precedent/jurisdiction',
 inviteTask: 'matter/invite',
 addEvent: 'event',
 getAllEvents: 'calendar/getAllEvents',
 genericEvents: 'matter/genericEvents',
 validateInvitation: 'invitation',
 sendQuestionnaire: 'user/send-questionnaire',
 getClientMatters: 'matter/client-matters',
 getCreator: 'matter/creator',
 toggleClientEdit: 'user/client-edit',
 searchMatter: 'matter/search',
 getOtherMatterId: 'matter/other-matter-id',
 refreshToken: 'auth/refresh',
 getFeesInfo: 'account/fees/',
 addInvoice: 'account/invoice',
 getInvoices: 'account/invoices/',
 getAllInvoices: 'account/all-invoices',
 getGeneralDisbursements: 'account/general-disbursements',
 getAllDisbursements: 'account/all-disbursements',
 addDisbursement: 'account/disbursement',
 downloadInvoice: 'account/download-invoice',
 sendInvoice: 'account/send-invoice',
 addPayment: 'account/add-payment',
 addReceipt: 'account/receipt',
 getTrustBalance: 'trust/trust-balance/',
 trust: 'trust/',
 getTrustReceipts: 'trust/receipts/',
 getUploadedReceipts: 'account/getUploadedReceipts/',
 getReceiptsWith: 'account/receipts/',
 refundAmount: 'account/refund',
 getRefundList: 'account/refund-list',
 getClientLedger: 'trust/client-ledger/',
 getCaseFiles: 'user/case-list',
 interTrustTransfer: 'trust/inter-trust-transfer',
 downloadTrustData: 'trust/download-pdf',
 removeReceipt: 'trust/remove-and-update-receipt',
 updateReceipt: 'trust/update-trust-receipt',
 generalDisbursement: 'account/general-disbursement',
 addLawfirmMember: 'user/add-member',
 authenticatedUser: 'user/authenticate-user',
 resetMemberPassword: 'user/reset-member-password',
 resetUserPassword: 'sendMail/reset-password',
 verifyMember: 'user/verify-member',
 verifyMemberPassword: 'user/verify-member-password',
 updatePrecedentOrder: 'precedent/update-order',
 updateDocumentsOrder: 'file/update-order',
 getZippedFiles: 'file/zip-files',
 uploadSignature: 'user/upload-signature',
 getSignature: 'user/get-signature',
 deleteSignature: 'user/delete-signature',
 createFolder: 'file/create-folder',
 renameFolder: 'file/rename-folder',
 deleteFolder: 'file/delete-folder',
 moveFile: 'file/move-file',
 restoreFile: 'file/restore-file',
 getFolders: 'file/get-folders',
 shareFolder: 'file/share-folder',
 getNotifications: 'notification',
 deleteNotification: 'notification/delete',
 deletePlayerId: 'notification/player-id',
 readNotification: 'notification/read',
 convertToPDF: 'file/convert-to-pdf',
 sendMessage: 'message/send',
 getMessages: 'message',
 generateForm: 'generate',
 addInterpreter: 'interpreter',
 getInterpreters: 'interpreter',
 deleteInterpreter: 'interpreter',
 updateInterpreter: 'interpreter',
 getCurrentUserInfo: 'user/get-user-info',
 memoInfo: 'memo',
 approval: 'approval',
 sendAuthCode: 'matter/send-auth-code',
 verifyToken: 'matter/verify',
 resendRequestSignature: 'matter/resend-request-signature',
 paymentReceipt: 'payment-receipt/savePaymentReceipt',
 getPaymentReceipt: 'payment-receipt/getPaymentReceipt',
 getAllPayementReceipt: 'payment-receipt/getAllReceipt',
 searchReceipt: 'payment-receipt/searchReceipt',
 saveNotes: 'payment-receipt/saveNotes',
 downloadReceipt: 'payment-receipt/downloadReceipt',
 sendEmailReceipt: 'payment-receipt/sendEmailReceipt',
 downloadReceipts: 'payment-receipt/dowloadReceipts',
 setSelectedColor: 'file/set-selected-color',
 updateFolderOrder: 'file/update-folder-order',
 saveMessageTemplateData: 'message-template/save-message-template',
 getMessageTemplateData: 'message-template/get-message-template',
 deleteTemplate: 'message-template/delete-message-template',
 serachTemplate: 'message-template/search-message-template',
 updateTemplateOrder: 'message-template/update-message-template-order',
 getAllMessage: 'message/get-all-message',
 createGlobalFolder: 'reference-folder/create-folder',
 getGlobalFolders: 'reference-folder/get-folder',
 renameGlobalFolder: 'reference-folder/rename-folder',
 addGlobalNoteToFolder: 'reference-folder/add-note',
 setColorToFolder: 'reference-folder/set-color',
 deleteGlobalFolder: 'reference-folder/delete-folder',
 updateGlobalFolderOrder: 'reference-folder/update-folder-order',
 getfile: 'reference-file/get-file',
 saveFile: 'reference-file/save-file',
 deleteFile: 'reference-file/delete-file',
 downloadGlobalFile: 'reference-file/download-file',
 getGlobalZippedFiles: 'reference-file/download-zip-file',
 restoreGlobalFile: 'reference-file/restore-file',
 updateGlobalFileName: 'reference-file/update-file-name',
 moveGlobalFile: 'reference-file/move-file',
 sharedGlobalFiles: 'reference-file/share-file',
 convertReferenceToPDF: 'reference-file/convert-to-pdf',
 updateReferenceFilesOrder: 'reference-file/update-files-order',
 readMessages: 'message/read-messages',
 uploadLocalMessageFile: 'message-template/upload-local-file',
 generateChatPdf: 'generate-chat-pdf',
 downloadChatAttachment: 'message/download-chat-attachment',
 saveRetainerTemplate: 'retainer-template/save-retainer-template',
 getRetainerTemplate: 'retainer-template/get-retainer-template',
 getSingleRetainerTemplate: 'retainer-template/get-single-retainer-template',
 getMasterRetainerTemplate: 'retainer-template/get-master-retainer-template',
 deleteRetainer: 'retainer-template/delete-retainer-template',
 resendRetainerSignatureRequest: 'retainer-template/resend-retainer-request',
 getRecentMatter: 'matter/get-recent-matter',
 generateRetainerPdf: 'generate-retainer-pdf',
 updateMessage: 'message/update',

 stripePreload: 'payments/preload',
 addPaymentDetails: 'payments/add-payment-details',
 paymentInvoices: 'payments/payment-invoices',
 getSubscriptions: 'payments/subscriptions',
 cancelSubscription: 'payments/cancel-subscription',
 cardDetails: 'payments/card/details',
 addCard: 'payments/card',
 setDefaultCard: 'payments/set-default-card',
 deleteCard: 'payments/card/delete',
};

export * from './courts';
export * from './familyForms';
export * from './validationMessages';

import { HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { Endpoints } from './../config';
import { ApiService } from './api.service';
import { DataService } from './data.service';
import { OneSignalService } from './one-signal.service';

@Injectable({
 providedIn: 'root',
})
export class AuthService implements OnDestroy {
 endpoint = Endpoints;
 headers: any;
 destroy$ = new Subject<boolean>();

 constructor(
  private route: Router,
  private api: ApiService,
  private storageService: DataService,
  private oneSignalService: OneSignalService,
  private toastrService: ToastrService,
 ) {}

 // register user to server
 register(data) {
  return this.api.post(this.endpoint.register, data);
 }

 // authenticate user to server
 signIn(data) {
  return this.api.post(this.endpoint.login, data);
 }

 // logout and clear login user session
 logout(data) {
  return this.api.post(this.endpoint.logOut, data);
 }

 // set user session on login or refresh session
 setToken(token) {
  const tokenArray = token.split('.');
  localStorage.setItem('authtoken', tokenArray[1]);
  localStorage.setItem('h', tokenArray[0]);
  localStorage.setItem('s', tokenArray[2]);
 }

 // fetch login user token for authenticate api
 getToken() {
  const token = localStorage.getItem('authtoken');
  const h = localStorage.getItem('h');
  const s = localStorage.getItem('s');
  if (token) {
   return `${h}.${token}.${s}`;
  }

  return null;
 }

 getTokenExpirationDate(token: string): Date {
  try {
   const decoded: any = jwt_decode(token);
   if (decoded.exp === undefined) return null;

   const date = new Date(0);
   date.setUTCSeconds(decoded.exp);
   return date;
  } catch (e) {
   return undefined;
  }
 }

 isTokenExpired(token?: string): boolean {
  if (!token) token = this.getToken();
  if (!token) return true;

  const date = this.getTokenExpirationDate(token);
  if (date === undefined) return true;
  return !(date.valueOf() > new Date().valueOf());
 }

 // set login user token in api request for authenticate api
 setHeader(method = '') {
  const token = this.getToken();
  if (token) {
   this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'x-auth-token': `${token}` });
  } else {
   this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  }
 }

 // check user is loggedInOr not
 checkLogin() {
  const token = this.getToken();
  return !!token;
 }

 setRequiredKeysOnLocalStorage(setupStatus?, userStatus?, level?) {
  localStorage.setItem('setupComplete', setupStatus);
  localStorage.setItem('isNewUser', userStatus);
  localStorage.setItem('userAccessLevel', level);
 }

 setUserDetails(user: any) {
  localStorage.setItem('userDetails', JSON.stringify(user));
 }

 getUserDetails() {
  return JSON.parse(localStorage.getItem('userDetails'));
 }

 setSetupComplete(status) {
  localStorage.setItem('setupComplete', status);
 }

 setIsNewUser(status) {
  localStorage.setItem('isNewUser', status);
 }

 setUserAccessLevel(level) {
  localStorage.setItem('userAccessLevel', level);
 }

 getSetupKey() {
  const key = localStorage.getItem('setupComplete');
  return key;
 }

 getUserAccessLevel() {
  try {
   const token = this.getToken();
   const decodeToken: any = jwt_decode(token);
   return decodeToken.accessLevel;
  } catch (e) {
   return 0;
  }
 }

 getIseNewUserStatus() {
  const key = localStorage.getItem('isNewUser');
  return key;
 }

 checkIsIsNewUser() {
  const isNewuser = localStorage.getItem('isNewUser');
  return isNewuser;
 }

 checkIsSetupComplete() {
  const isSetupDone = this.getSetupKey();
  return isSetupDone;
 }

 checkUserAccessLevel() {
  try {
   const token = this.getToken();
   const decodeToken: any = jwt_decode(token);
   const accessLevel = decodeToken.accessLevel;
   if (Number.parseInt(accessLevel) >= 4) {
    return true;
   }

   if (Number.parseInt(accessLevel) < 4) {
    return false;
   }
  } catch (e) {
   return false;
  }
 }

 async refreshToken() {
  const token = this.getToken();
  if (!token) return;

  const date = this.getTokenExpirationDate(token);
  if (!date) return;
  let timeStamp = this.storageService.getTimeStamp();

  if (!timeStamp) {
   timeStamp = Date.now();
   this.storageService.setTimeStamp(timeStamp);
  }

  const now = moment(new Date());
  const end = moment(new Date(timeStamp));
  const duration = moment.duration(now.diff(end));
  const hours = +duration.asHours().toFixed(2);

  /**
   * Now check if the difference is greater than 1 then check if the token is expired
   */
  if (hours >= 1) {
   /**
    * If the token is expired then logout the user and clear timeStamp
    */
   // if (this.isTokenExpired()) {
   this.navigateTo(true, null, null);
   // }
  } else {
   /**
    * First calculate difference between token expiration date and current date
    */
   const expiryDate = moment(new Date(date));
   const tokenDuration = moment.duration(expiryDate.diff(now));
   const remainigHours = +tokenDuration.asHours().toFixed(2);

   /**
    * If the Difference is less than or equal to 1 then refresh token
    */
   if (remainigHours <= 1) {
    const response = await this.api.post(this.endpoint.refreshToken, {}).toPromise();
   }
   this.storageService.setTimeStamp(Date.now());
  }
 }

 async navigateTo(clearStorage: boolean, state?: any, user?: any) {
  if (!clearStorage) {
   if (state?.url !== null) {
    const lastRoute = this.storageService.getLastRoute();

    user?._id !== ''
     ? this.route.navigate(['/auth'], { state: { url: lastRoute, userId: user?._id } })
     : this.route.navigate(['/auth'], { state: { url: state.url } });
   } else {
    this.route.navigate(['/auth']);
   }
   const playerId = await this.oneSignalService.getPlayerIdFromLocalStorage();
   const userId = await this.oneSignalService.getCurrentUserDetails();
   if (playerId && userId) {
    await this.oneSignalService.deletePlayerId(playerId, userId);
   }
   this.storageService.clearStorage(clearStorage);
  } else {
   this.storageService.clearStorage(clearStorage);
   this.route.navigate(['/auth']);
  }
 }

 checkChildActivated(state: any) {
  const userLoggedIn = this.checkLogin();
  const isTokenExpired = this.isTokenExpired();
  if (state.url !== '/') {
   this.storageService.setPreviousUrl(state.url);
  }
  this.refreshToken();
  if (userLoggedIn && !isTokenExpired) {
   return true;
  }
  const userData = this.getUserDetails();

  if (state.url.includes('data') || state.url.includes('brief-dockets')) {
   this.navigateTo(false, state, userData);
  } else if (this.storageService.getLastRoute() !== null) {
   this.navigateTo(false, state, userData);
  } else {
   this.navigateTo(false, null, null);
  }
  return false;
 }

 async getSubscriptionExpiry(): Promise<any> {
  try {
   const res: any = await this.api.get(Endpoints.subscriberExpiry).toPromise();
   return res || '';
  } catch (error) {
   this.toastrService.error(error.error || 'Something went wrong', 'Alert');
   return '';
  }
 }

 forgotPassword(data): Observable<any> {
  return this.api.post(this.endpoint.resetUserPassword, data);
 }

 ngOnDestroy(): void {
  this.destroy$.next();
  this.destroy$.complete();
 }
}
